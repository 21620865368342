import React from 'react';

const IconScholar = () => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-scholar">
    <title>Scholar</title>
    <path d="M12 2L2 9l10 6 10-6-10-7z" /> {/* Base shape */}
    <path d="M2 9v6l10 6 10-6V9" />         {/* Outer fold */}
    <path d="M12 15l10-6" />               {/* Inner detail */}
    <path d="M12 15L2 9" />
    <path d="M6 10v5a6 6 0 0012 0v-5" />   Arc for 'book'
</svg>
);

export default IconScholar;
